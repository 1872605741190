"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  getFormattedNumber() {
    if (this.number) {
      return this._formatNumber(this.number);
    }
    return this.number;
  }
  _formatNumber(numberToFormat = 0) {
    numberToFormat = numberToFormat + '' || '';
    // works for integer and floating as well
    let numberAndDecimal = numberToFormat.split('.');
    const decimals = numberAndDecimal[1] || null;
    numberAndDecimal = numberAndDecimal[0].replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
    numberToFormat = decimals ? numberAndDecimal + '.' + decimals : numberAndDecimal;
    return numberToFormat;
  }
  _numberToWords() {
    let numberToFormat = this.number.split('.')[0];
    const sglDigit = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const dblDigit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tensPlace = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const handleTens = function (dgt, prevDgt) {
      return dgt === 0 ? '' : ' ' + (dgt === 1 ? dblDigit[prevDgt] : tensPlace[dgt]);
    };
    const handleUtlc = function (dgt, nxtDgt, denom) {
      return (dgt !== 0 && nxtDgt !== 1 ? ' ' + sglDigit[dgt] : '') + (nxtDgt !== 0 || dgt > 0 ? ' ' + denom : '');
    };
    let str = '';
    let digitIdx = 0;
    let digit = 0;
    let nxtDigit = 0;
    const words = [];
    if (numberToFormat += '', isNaN(parseInt(numberToFormat))) str = '';else if (parseInt(numberToFormat) > 0 && numberToFormat.length <= 10) {
      for (digitIdx = numberToFormat.length - 1; digitIdx >= 0; digitIdx--) {
        switch (digit = numberToFormat[digitIdx] - 0, nxtDigit = digitIdx > 0 ? numberToFormat[digitIdx - 1] - 0 : 0, numberToFormat.length - digitIdx - 1) {
          case 0:
            words.push(handleUtlc(digit, nxtDigit, ''));
            break;
          case 1:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 2:
            words.push(digit !== 0 ? ' ' + sglDigit[digit] + ' Hundred' + (numberToFormat[digitIdx + 1] !== 0 && numberToFormat[digitIdx + 2] !== 0 ? ' and' : '') : '');
            break;
          case 3:
            words.push(handleUtlc(digit, nxtDigit, 'Thousand'));
            break;
          case 4:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 5:
            words.push(handleUtlc(digit, nxtDigit, 'Lakh'));
            break;
          case 6:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 7:
            words.push(handleUtlc(digit, nxtDigit, 'Crore'));
            break;
          case 8:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 9:
            words.push(digit !== 0 ? ' ' + sglDigit[digit] + ' Hundred' + (numberToFormat[digitIdx + 1] !== 0 || numberToFormat[digitIdx + 2] !== 0 ? ' and' : ' Crore') : '');
        }
      }
      str = words.reverse().join('').trim().replace(/and$/, '');
    } else str = 'Zero';
    return str;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ''
})], default_1.prototype, "size", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "number", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Inr',
  components: {}
})], default_1);
var _default = exports.default = default_1;