"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _seller = require("@/store/modules/seller");
var _validate = require("@/utils/validate");
var _index = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validateUsername = (rule, value, callback) => {
      if (!(0, _validate.isValidEmail)(value)) {
        callback(new Error('Please enter valid email'));
      } else {
        callback();
      }
    };
    this.validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('Please enter your password'));
      } else {
        callback();
      }
    };
    this.loginForm = {
      mobileNumber: '',
      otp: ''
    };
    this.loginRules = {
      mobileNumber: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        pattern: /^\d+$/,
        message: 'Please enter numbers only',
        trigger: 'blur'
      }, {
        min: 10,
        max: 10,
        message: 'Length should be 10',
        trigger: 'blur'
      }]
      // mobileNumber: [{ validator: this.validateUsername, trigger: 'blur' }],
      // otp: [{ validator: this.validatePassword, trigger: 'blur' }]
    };
    this.passwordType = 'password';
    this.loading = false;
    this.showDialog = false;
    this.otherQuery = {};
    this.showOtpField = false;
    this.isNumberFieldDisabled = false;
    this.resendCount = 0;
    this.resendExpiryTime = 0;
    this.resend = false;
    this.resendTimer = 0;
  }
  onRouteChange(route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }
  mounted() {
    if (this.loginForm.mobileNumber === '') {
      this.$refs.mobileNumber.focus();
    } else if (this.loginForm.otp === '') {
      // (this.$refs.otp as Input).focus();
    }
    // get resendCount from localstorage
    this.resendCount = +(window.localStorage.getItem('OTP_RESEND_COUNT') || '0');
    this.resendExpiryTime = +(window.localStorage.getItem('OTP_RESEND_TIME') || new Date().getTime().toString());
    this.startResendTimeout();
  }
  showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = '';
    } else {
      this.passwordType = 'password';
    }
    this.$nextTick(() => {
      this.$refs.password.focus();
    });
  }
  editNumber() {
    this.showOtpField = false;
    this.isNumberFieldDisabled = false;
  }
  handleResendOtp() {
    this.resendCount += 1;
    // set in local storage
    window.localStorage.setItem('OTP_RESEND_COUNT', this.resendCount.toString());
    // check
    if (this.resendCount <= 3) {
      this.handleRequestOtp();
      return true;
    } else {
      this.$message({
        message: 'Resend limit exceed',
        type: 'warning',
        duration: 1000 * 5
      });
      this.resendExpiryTime = new Date().getTime() + 5 * 60 * 1000;
      window.localStorage.setItem('OTP_RESEND_TIME', this.resendExpiryTime.toString());
      this.startResendTimeout();
      return false;
    }
  }
  handleRequestOtp() {
    this.$refs.loginForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          const hash = await _seller.SellerModule.RequestOtp(this.loginForm);
          this.showOtpField = true;
          this.isNumberFieldDisabled = true;
          this.resend = true;
          this.resendTimer = Date.now() + 1000 * 60;
        } catch (error) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
  handleVerifyOtp() {
    this.$refs.loginForm.validate(async valid => {
      if (valid) {
        // check if mobile number & otp present
        if (!this.loginForm.mobileNumber || !this.loginForm.otp) {
          return false;
        }
        this.loading = true;
        try {
          await _seller.SellerModule.VerifyOtp(this.loginForm);
          this.$router.push({
            path: this.redirect || '/',
            query: this.otherQuery
          });
          // delete local storage
          this.resetResendOTPFlags();
        } catch (error) {
          this.$message({
            message: error,
            type: 'warning',
            duration: 1000 * 5
          });
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
  getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {});
  }
  handleEnterKey(event) {
    this.handleRequestOtp();
  }
  validateMobile(value) {
    if (value) {
      // Allow only digits
      // Update the model with the filtered value
      this.loginForm.mobileNumber = value.replace(/\D/g, '');
    }
  }
  handleOTPInput(value) {
    if (value) {
      // Allow only digits
      // Update the model with the filtered value
      this.loginForm.otp = value.replace(/\D/g, '');
    }
  }
  startResendTimeout() {
    // check if resendCount > 3, setTimeout to clear
    if (this.resendCount > 3) {
      this.resendTimer = this.resendExpiryTime;
      setTimeout(() => {
        this.resetResendOTPFlags();
      }, this.resendExpiryTime - new Date().getTime());
    }
  }
  resetResendOTPFlags() {
    window.localStorage.removeItem('OTP_RESEND_COUNT');
    window.localStorage.removeItem('OTP_RESEND_TIME');
    this.resendCount = 0;
    this.resendExpiryTime = 0;
    this.resendTimer = undefined;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('$route', {
  immediate: true
})], default_1.prototype, "onRouteChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Login',
  components: {
    LangSelect: _index.default
  }
})], default_1);
var _default = exports.default = default_1;