"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SellerModule = void 0;
require("core-js/modules/es.error.cause.js");
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _admins = require("@/api/admins");
var _sellersUsers = require("@/api/sellers-users");
var _cookies = require("@/utils/cookies");
var _router = _interopRequireWildcard(require("@/router"));
var _permission = require("./permission");
var _tagsView = require("./tags-view");
var _store = _interopRequireDefault(require("@/store"));
let Seller = class Seller extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.token = (0, _cookies.getToken)() || '';
    this.firstName = '';
    this.lastName = '';
    this.avatar = '';
    this.roles = [];
    this.mobileNumber = '';
    this.email = '';
    this.id = 0;
    this.sellersRoleId = 0;
    this.sellerId = 0;
    this.hash = '';
  }
  SET_TOKEN(token) {
    this.token = token;
  }
  SET_FIRST_NAME(firstName) {
    this.firstName = firstName;
  }
  SET_LAST_NAME(lastName) {
    this.lastName = lastName;
  }
  SET_AVATAR(avatar) {
    if (avatar) {
      this.avatar = avatar;
    }
  }
  SET_ID(id) {
    this.id = id;
  }
  SET_SELLERS_ROLE_ID(sellersRoleId) {
    this.sellersRoleId = sellersRoleId;
  }
  SET_SELLER_ID(sellerId) {
    this.sellerId = sellerId;
  }
  SET_ROLES(roles) {
    this.roles = roles;
  }
  SET_EMAIL(email) {
    this.email = email;
  }
  SET_MOBILE_NUMBER(mobileNumber) {
    this.mobileNumber = mobileNumber;
  }
  SET_HASH(hash) {
    this.hash = hash;
  }
  async RequestOtp(selelrInfo) {
    const {
      mobileNumber
    } = selelrInfo;
    try {
      const data = await (0, _sellersUsers.requestOtp)({
        mobileNumber
      });
      this.SET_HASH(data.otp.hash);
      return data;
    } catch (error) {
      this.hash = '';
      return Promise.reject(error);
    }
  }
  async VerifyOtp(selelrInfo) {
    const {
      mobileNumber,
      otp
    } = selelrInfo;
    try {
      const requestData = {
        mobileNumber,
        otp,
        hash: this.hash
      };
      const data = await (0, _sellersUsers.verifyOtp)(requestData);
      if (!data.user) {
        throw new Error(data.message);
      }
      (0, _cookies.setToken)(data.user.accessToken);
      this.SET_TOKEN(data.user.accessToken);
      return (0, _sellersUsers.getSellersUserInfo)({});
    } catch (error) {
      this.ResetToken();
      return Promise.reject(error);
    }
  }
  ResetToken() {
    (0, _cookies.removeToken)();
    this.SET_TOKEN('');
    this.SET_SELLERS_ROLE_ID(0);
    this.SET_ROLES([]);
  }
  async GetSellerInfo() {
    if (this.token === '') {
      throw Error('GetSellerInfo: token is undefined!');
    }
    const data = await (0, _sellersUsers.getSellersUserInfo)({});
    if (!data) {
      throw Error('Verification failed, please Login again.');
    }
    const avatarData = await (0, _sellersUsers.getSellersUserById)(data.id);
    this.SET_ROLES([data.role]);
    this.SET_FIRST_NAME(data.firstName);
    this.SET_LAST_NAME(data.lastName);
    this.SET_AVATAR(avatarData.avatar);
    this.SET_ID(data.id);
    this.SET_SELLER_ID(data.sellerId);
    this.SET_EMAIL(data.emailAddress);
    this.SET_MOBILE_NUMBER(data.mobileNumber);
    return data;
  }
  async ChangeRoles(role) {
    // Dynamically modify permissions
    this.SET_ROLES([role]);
    const token = role + '-token';
    this.SET_TOKEN(token);
    (0, _cookies.setToken)(token);
    await this.GetSellerInfo();
    (0, _router.resetRouter)();
    // Generate dynamic accessible routes based on roles
    _permission.PermissionModule.GenerateRoutes(this.roles);
    // Add generated routes
    _router.default.addRoutes(_permission.PermissionModule.dynamicRoutes);
    // Reset visited views and cached views
    _tagsView.TagsViewModule.delAllViews();
  }
  async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    await (0, _admins.logout)();
    (0, _cookies.removeToken)();
    (0, _router.resetRouter)();
    // Reset visited views and cached views
    _tagsView.TagsViewModule.delAllViews();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_TOKEN", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_FIRST_NAME", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_LAST_NAME", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_AVATAR", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_ID", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_SELLERS_ROLE_ID", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_SELLER_ID", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_ROLES", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_EMAIL", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_MOBILE_NUMBER", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Seller.prototype, "SET_HASH", null);
(0, _tslib.__decorate)([(0, _vuexModuleDecorators.Action)({
  rawError: true
})], Seller.prototype, "RequestOtp", null);
(0, _tslib.__decorate)([(0, _vuexModuleDecorators.Action)({
  rawError: true
})], Seller.prototype, "VerifyOtp", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Seller.prototype, "ResetToken", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Seller.prototype, "GetSellerInfo", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Seller.prototype, "ChangeRoles", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Seller.prototype, "LogOut", null);
Seller = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'admin'
})], Seller);
const SellerModule = exports.SellerModule = (0, _vuexModuleDecorators.getModule)(Seller);